import React from 'react';
import { ReactTyped } from "react-typed";

const Aiadlogo = () => {
  return (
    <div className='text-white md:pb-[50px] pb-[100px]'>
<div className='flex items-end justify-center mt-[10px]'>
</div>

      <div className='max-w-[800px] mt-[10px] w-full h-auto mx-auto text-center flex flex-col justify-center'>
        <p className='text-[#00df9a] font-bold p-2'>
          
        </p>
        <h1 className='text-[#5fe7f7] font-bold md:text-8xl sm:text-5xl text-6xl p-2'>AI Powered Ads
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-3xl sm:text-4xl text-xl font-bold py-4'>
            Revolusion for
          </p>

          
          <ReactTyped
          className='md:text-3xl sm:text-3xl text-xl md:pl-4 pl-2 font-light'
            strings={['Advertisers ', 'Affiliates', 'Marketing agencies', 'Content creators ', 'Investors ', 'Enterprise Sector']}
            typeSpeed={50}
            backSpeed={30}
            autoInsertCss
            fadeOutDelay={50}
            showCursor
            loop
          />
        </div>
        <div className='justify-center items-center pb-2 mt-4 text-4xl'>
         <h1 className='font-bold bg-gradient-to-r from-purple-800 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text md:text-4xl text-2xl '>On Solana Blockchain</h1>
        </div>
        <p className='md:text-2xl text-xl font-bold text-gray-500 p-5 md:p-1'>Bringing transparency, efficiency and practicality</p>
        <form action="https://docs.google.com/forms/d/1t1DxZfhfQpTjoRK0jvCA6RTh_pl87cy9acfabe-4yhA/viewform?edit_requested=true" target='_blank' rel='noopener noreferrer'>
        <button className='bg-gradient-to-bl from-[#4ab1f5] to-[#69e99c] w-[200px] rounded-md font-medium mt-[40px] my-6 mx-auto py-3 text-black'>Get Started</button></form>
      </div>
    </div>
  );
};

export default Aiadlogo;
