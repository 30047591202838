import React from 'react'

const Newsletter = () => {
  return (
    <div>
      <div className='grid md:grid-cols-2 bg-[#1d1d1d] py-16 text-white px-4'>

<div className='grid md:grid-rows-2 md:px-[80px] px-[25px] items-center justify-center'>
<div>
  <p className='md:text-4xl sm:text-4xl text-2xl text-center font-bold justify-center items-center'>Get updates on the project</p>
 
  </div>
<div><h2 className='md:text-left text-center'>Sign up for our newsletter and stay up to date</h2></div>

</div>


<div className='grid md:grid-rows-2 md:px-[80px] px-[25px] items-center justify-center'>
<div></div>
<div><form action="https://docs.google.com/forms/d/1t1DxZfhfQpTjoRK0jvCA6RTh_pl87cy9acfabe-4yhA/viewform?edit_requested=true" target='_blank' rel='noopener noreferrer'><button className='bg-[#1b6971] text-white rounded-md font-medium w-[200px] px-6 py-3 md:my-0 my-[25px] '>Subscribe</button>
        </form></div>

</div>
     
      </div>
    </div>
  )
}

export default Newsletter;