import React from 'react';
import Joker from '../assets/Joker.png';
import { Link } from 'react-router-dom';

function Coremetrics() {
  return (
    <div className="screen w-full justify-center items-center border-none ">
      <div className='grid md:grid-cols-2'>
        <div className='flex justify-center items-center md:p-4 p-[25px]'>
          <div className="max-w-lg p-7 bg-gradient-to-r from-[#093824] to-[#012f3a] border border-gray-200 rounded-3xl shadow transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <h2 className="text-5xl font-bold tracking-tight text-gray-200">Core Metrics</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-200">
              A tamper-proof and transparent advertisement ecosystem that allows advertisers and affiliates to track the performance of their ads in real-time, ensuring accurate data and preventing click fraud. Metrics are recorded on the Solana blockchain, making them transparent, secure, and verifiable.
            </p>
            <br />
            <Link to="/Beta">
              <div className="inline-flex items-center px-3 py-2 text-sm mb-7 font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                BETA
              </div>
            </Link>
          </div>
        </div>
        <div className='flex justify-center items-center md:p-4 p-[25px]'>
          <img className='animate-none md:p-[100px] py-20' src={Joker} alt="Joker" />
        </div>
      </div>
    </div>
  );
}

export default Coremetrics;
