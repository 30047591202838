import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Aiadlogo from './components/Aiadlogo';
import Navbar from './components/Navbar';
import Newsletter from './components/Newsletter';
import Beta from './components/Beta';
import Documentation from './components/Documentation';
import Aiadtokenomics from './components/Aiadtokenomics';
import Firstview from './components/Firstview';
import Coremetrics from './components/Coremetrics';
import Tokencard from './components/Tokencard';
import Plantimage from './components/Plantimage';
import Card2 from './components/Card2';
import Banner from './components/Banner';
import Aiadglimpse from './components/Aiadglimpse';
import Slidercards from './components/Slidercards';
import Aiadgoldenpassnft from './components/Aiadgoldenpassnft';
import Slidercardsmobile from './components/Slidercardsmobile';
import Aiadgoldenpassnftmobile from './components/Aiadgoldenpassnftmobile';
function App() {
  return (
    <Router>
      <div>
        <Navbar />
         <Routes>
          <Route path="/Beta" element={<Beta />} /> {/* This will only show when the URL path is '/about' */}
          <Route path="/Documentation" element={<Documentation />} /> {/* This will only show when the URL path is '/Documentation' */}
          <Route path="/" element={<> {/* Wrap existing components in an empty fragment */}
            <Banner />
            <Firstview />
            <Aiadlogo />
            <Aiadglimpse />
            <Coremetrics />
            <Plantimage />
            <Slidercards />
            <Slidercardsmobile />
            <Tokencard />
            <Aiadtokenomics />
            <Aiadgoldenpassnft />
            <Aiadgoldenpassnftmobile />
            <Card2 />
            <Newsletter />
         
            
            
          </>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
