import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Goldenpassaiadnftimage from '../assets/AIAD_Golden_Pass_AIAD_NFT.png';
import Goldenpassaiadnftimagebw from '../assets/AIAD_Golden_Pass_NFT._Teaser_BW.png';


function Aiadgoldenpassnftmobile() {
   
        return (
          <div className="block md:hidden screen bg-gradient-to-b from-black via-[#3a3300] to-[#000000] w-full justify-center items-center border-none py-[200px] px-[25px]">
            <div className='flex items-center justify-center'>
            <h1 className='font-bold bg-gradient-to-r inline-block from-[#fc8f00] via-[#fcf300] to-[#e27f28] bg-clip-text text-transparent text-2xl pb-5'>111 AIAD Golden Pass</h1>
            </div>
            
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={20}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 4500,
                disableOnInteraction: true,
              }}
              pagination={{ clickable: true }}
              navigation
            >
              {/* 1st Slide */}
              <SwiperSlide>
                <div className='flex justify-center items-center md:px-10 py-10 md:p-1 '>
                  <img className='md:h-[320px] h-auto md:max-w-sm border-8 border-[#03150c] rounded-3xl' src={Goldenpassaiadnftimage} alt='AIAD Golden Pass NFT'></img>
                </div>
              </SwiperSlide>
      
              {/* 2nd Slide */}
              <SwiperSlide>
              <div className='flex justify-center items-center md:px-10 py-10 md:p-1 '>
                  <img className='md:h-[320px] h-auto md:max-w-sm border-8 border-[#03150c] rounded-3xl' src={Goldenpassaiadnftimagebw} alt='AIAD Golden Pass NFT'></img>
                </div>
              </SwiperSlide>
      
              {/* 3rd Slide */}
              <SwiperSlide>
              <div className='flex justify-center items-center md:px-10 py-10 md:p-1 '>
                  <img className='md:h-[320px] h-auto md:max-w-sm border-8 border-[#03150c] rounded-3xl' src={Goldenpassaiadnftimage} alt='AIAD Golden Pass NFT'></img>
                </div>
              </SwiperSlide>
      
              {/* 4th Slide */}
              <SwiperSlide>
              <div className='flex justify-center items-center md:px-10 py-10 md:p-1 '>
                  <img className='md:h-[320px] h-auto md:max-w-sm border-8 border-[#03150c] rounded-3xl' src={Goldenpassaiadnftimagebw} alt='AIAD Golden Pass NFT'></img>
                </div>
              </SwiperSlide>

              {/* 5th Slide */}
              <SwiperSlide>
              <div className='flex justify-center items-center md:px-10 py-10 md:p-1 '>
                  <img className='md:h-[320px] h-auto md:max-w-sm border-8 border-[#03150c] rounded-3xl' src={Goldenpassaiadnftimage} alt='AIAD Golden Pass NFT'></img>
                </div>
              </SwiperSlide>

                {/* 6th Slide */}
                <SwiperSlide>
                <div className='flex justify-center items-center md:px-10 py-10 md:p-1 '>
                  <img className='md:h-[320px] h-auto md:max-w-sm border-8 border-[#03150c] rounded-3xl' src={Goldenpassaiadnftimagebw} alt='AIAD Golden Pass NFT'></img>
                </div>
              </SwiperSlide>


            </Swiper>
          </div>
        );
      }

export default Aiadgoldenpassnftmobile;
