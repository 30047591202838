import React from 'react'

function Tokencard() {
  return (
    <div className="screen w-auto justify-center items-center border-none">


<div className='flex justify-center items-center md:mt-[200px]  md:p-10 p-[25px]'>
          <div className="max-w-lg p-7 bg-gradient-to-r from-[#030303] to-[#012f3a] border border-gray-200 rounded-3xl shadow transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105 ">
            <h2 className="mb-2 text-3xl font-bold tracking-tight text-white">Tokenomics of AIAD</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-200">
            AIAD's tokenomics strategy is designed to ensure the long-term viability and growth of the 
platform. The distribution of AIAD tokens is as follows:
            </p>
            <br />
            
          </div>
        </div>


    </div>
  )
}

export default Tokencard