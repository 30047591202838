import React from 'react';


function Card2() {
  return (
    <div className="screen w-full justify-center items-center border-none p-[25px] ">
      <div className='grid md:grid-cols-2'>
        <div className='flex justify-center items-center '>
        <div className="h-[300px] max-w-lg p-7 bg-gradient-to-r from-[#000000] to-[#3e3e3e] border border-gray-200 rounded-3xl shadow transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <h2 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">Enhanced Transparency</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-200">
            By utilizing blockchain, AIAD brings unparalleled transparency to digital advertising, allowing 
for a more honest and effective market environment.
            </p>
            <br />
            
          </div>
        </div>






        <div className='flex justify-center items-center py-10'>
        <div className='flex justify-center items-center py-10'>
          <div className="h-[300px] max-w-lg p-7 bg-gradient-to-r from-[#030303] to-[#3e3e3e] border border-gray-200 rounded-3xl shadow transition duration-700 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <h2 className="mb-2 text-3xl font-bold tracking-tight dark:text-white">Development</h2>
            <br />
            <p className="text-1xl mb-3 font-normal text-gray-200">
            Investment in ongoing development is critical for the platform's scalability, security, and the 
introduction of new features. This ensures the platform remains competitive and relevant.

            </p>
            <br />
            
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Card2;
